* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #f9f9f9;
  font-family: Roboto , Arial , Helvetica Neue , Helvetica , sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

a {
  color: #333;
}

h4 {
  font-weight: 700;
}


.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
}


ul {
  list-style-type: none;
}

footer {
  background-color: aquamarine;
  bottom: 0;
  position: fixed;
  text-align: center;
  width: 100%;
}


.selected {
  background-color: aqua;
  color: red;
  font-size: 36px;
}

.active {
  color: #034422;
  font-size: 2rem;
  font-weight: 700;
}

.page__404 {
  font-size: 6rem;
  padding: 40px;
}
