.Header {
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0 40px;
}

.Header img {
  max-width: 100%;
  width: 100px;
}
