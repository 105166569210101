.Sidebar {
  background-color: white;
  border-right: 1px solid #e1e1e1;
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 40px;
  width: 220px;
}

.Sidebar nav {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}

.Sidebar a {
  align-items: center;

  /* background-color: red; */

  /* color: #3e3e3e; */
  cursor: pointer;
  display: inline-flex;
  font-size: 1.2rem;
  height: 28px;
  margin-bottom: 12px;
  text-decoration: none;
}

.Sidebar svg {
  margin-right: 8px;
}

.Sidebar a:hover {
  background-color: #e1e1e1;
  text-decoration: none;
}
